.t-main-title {
  font-size: clamp(5vh, 5vw, 3rem);
}

.t-title {
  font-size: clamp(4vh, 4vw, 2.5rem);
}

.t-sub-title {
  font-size: clamp(3vh, 2vw, 2rem);
}

.t-paragraph {
  font-size: clamp(3vh, 1.3vw, 1.5rem);
}

.t-description {
  font-size: clamp(1.6vh, 1vw, 1.4rem);
}

.t-button {
  font-size: clamp(1.9vh, 1.2vw, 1.4rem);
}

.text-justify {
  text-align: justify;
}

.t-green {
  color: #2B8354 !important ;
}

.t-blue {
  color: #0d2a48;
}

.t-withe {
  color: #fff;
}

@media (min-width: 768px) {
  .text-md-justify {
    text-align: justify !important;
  }
}

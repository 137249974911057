.scroll {
  top: 0;
  position: fixed;
  z-index: 10;
  height: 100vh;
  width: 5vw;
  right: 5px;
}

.point {
  background-color: rgb(255, 255, 255);
  width: 20px;
  height: 20px;
  border: 0;
  padding: 0;
  border-radius: 100%;
  margin-block: 1rem;
  text-decoration: none;
}

.active {
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid white;
}

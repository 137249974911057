.contact-height {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.contact-image-container {
  width: 100%;
  min-height: 100%;
  position: absolute;
  overflow: hidden;
}

.contact-image-container img {
  margin-left: 25%;
  width: 100%;
  opacity: 0.5;
  z-index: 0;
  mask: radial-gradient(ellipse closest-side at center, #fff, transparent 100%);
}

.form-control,
.form-select {
  border-radius: 20px;
}

@media (max-width: 767px) {
  .contact-image-container img {
    margin-left: 0;
  }
}

.t-description a {
  color: white !important;
}

input[type="range"] {
  height: 10px;
  -webkit-appearance: none;
  margin-block: 20px;
  width: 50%;
  pointer-events: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 26px;
  background: #89c599;
  border-radius: 8px;
  border: 2px solid #0d2a48;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #a6a6a600;
  border: 1px solid #0d2a48;
  height: 39px;
  width: 39px;
  border-radius: 11px;
  background: #0d2a48;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
  pointer-events: auto;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #89c599;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #89c599;
  border-radius: 8px;
  border: 2px solid #0d2a48;
  pointer-events: auto;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 26px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #89c599;
  border: 2px solid #0d2a48;
  border-radius: 16px;
}

input[type="range"]::-ms-fill-upper {
  background: #89c599;
  border: 2px solid #0d2a48;
  border-radius: 16px;
}

input[type="range"]::-ms-thumb {
  margin-top: 1px;
  border: 1px solid #f27b7f;
  height: 39px;
  width: 33px;
  border-radius: 11px;
  background: #0d2a48;
  cursor: pointer;
  pointer-events: auto;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #89c599;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #89c599;
}

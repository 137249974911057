@font-face {
  font-family: "QuendaMedium";
  src: local("Quenda-Medium"),
    url(../fonts/Quenda-Medium.otf) format("opentype");
  font-display: swap;
}

.no-margin {
  margin: 0;
}

.container-padding {
  padding-inline: 6rem;
  padding-block: 2rem;
  position: relative;
  z-index: 1;
}

.height {
  min-height: 100vh;
}

.blue-purple-background {
  background-image: linear-gradient(293deg, #9c1adf 10%, #0d2a48 100%);
}

.full-purple-background {
  background-image: linear-gradient(293deg, #a101f1 2%, #382863 100%);
}

.purple-green-background {
  background-image: linear-gradient(293deg, #89c599 10%, #9c1adf 100%);
}

.soft-green-background {
  background-image: linear-gradient(293deg, #56ab8a 10%, #2c5f63);
}

.green-background {
  background-image: linear-gradient(293deg, #89c599 10%, #0d2a48 100%);
}

.black-green-background {
  background-image: linear-gradient(293deg, #0d2a48 10%, #89c599 100%);
}

.purple-background {
  background-image: linear-gradient(293deg, #0d2a48 10%, #9c1adf 100%);
}

.pink-background {
  background-image: linear-gradient(293deg, #9c1adf 10%, #f65097 100%);
}

.pink-blue-background {
  background-image: linear-gradient(293deg, #0d2a48 10%, #f65097 100%);
}

.purple-pink-background {
  background-image: linear-gradient(293deg, #f65097 10%, #9c1adf 100%);
}

.pink-green-background {
  background-image: linear-gradient(293deg, #89c599 10%, #f65097 100%);
}

.t-color-blue {
  color: #0d2a48;
}

.t-pink {
  color: #cf2871;
}

.b-color-green {
  background-color: #89c599;
}

body {
  font-family: "QuendaMedium";
  position: relative;
}

h1,
p,
h2,
h3,
h4,
button,
input,
nav {
  color: white;
  font-family: "QuendaMedium";
}

h1,
h2,
h3,
h4 {
  width: 100%;
}

a {
  text-decoration: none;
}

li {
  margin-block: 10px;
  color: white;
}

section {
  padding-top: 80px;
}

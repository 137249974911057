.main-height {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.text-container {
  position: relative;
  width: 100%;
}

.info {
  z-index: 1;
  padding-block: 5rem;
}

.text-container {
  width: 80%;
}

.main-margin {
  margin-block: 2rem;
}

.main-img {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
}

.main-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.7;
}

.text-container {
  width: 100%;
  padding-block: 4rem;
}

@media (max-width: 767px) {
  .main-img {
    left: 0;
    width: 100%;
    bottom: 0px;
  }

  .main-img img {
    width: 100%;

    z-index: 0;
  }

  .text-container {
    text-align: center;
  }
}

@media (max-height: 525px) {
  .main-height {
    height: auto;
  }
}

@media (min-height: 525px) and (min-width: 992px) and (max-width: 1000px) {
  .main-height {
    height: 625px;
    position: relative;
  }

  .main-img {
    left: 50%;
    bottom: 0px;
  }

  .main-img img {
    width: 100%;
  }
}

@media (max-width: 612px) {
  .container-padding {
    padding-inline: 2rem;
  }
}

.questions-height {
  min-height: 100vh;
  position: relative;
}

.questions-background {
  background-image: linear-gradient(293deg, #52ff80 10%, #0d2a48 100%);
  overflow: hidden;
}

.FAQs-image-container {
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.FAQs-image-container img {
  margin-left: 25%;
  width: 100%;
  opacity: 0.5;
  z-index: 0;
  mask: radial-gradient(ellipse closest-side at center, #fff, transparent 100%);
}

@media (max-width: 767px) {
  .FAQs-image-container img {
    margin-left: 0;
  }
}

.custom-acordion {
  margin: 10px;
}

.acordion-h-active {
  background-color: #e2e2e200;
  border-bottom: 2px solid white;
  transition: all 0.2s ease-in-out;
}

.acordion-header button {
  width: 100%;
  background-color: #ffffff00;
  border: 0;
}

.acordion-body {
  overflow: hidden;
  display: none;
  transition: all 0.5s ease-in-out;
}

.acordion-body-active {
  display: block;
}

.arrow {
  border: solid rgb(252, 252, 252);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.footer {
  padding-block: 20px;
}

.t-color {
  color: black;
}

.footer img {
  height: 125px;
}

.features-height {
  min-height: 100vh;
  overflow: hidden;
}

.feature-img-container {
  position: relative;
  height: 500px;
}

.feature-img img {
  width: 100%;
  margin-left: -25px;
  padding-inline: 2rem;
}

.feature img {
  width: 5rem;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .col-lg-3-5 {
    flex: 0 0 auto;
    width: 29.1666667%;
  }
}

@media (min-width: 768px) {
  .feature-inline {
    margin-top: 6rem;
    padding-inline: 50px;
  }
}

.partner-height {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.partner-container {
  height: 100%;
  width: 90%;
  top: 0;
  position: absolute;
  overflow: hidden;
}

.partner-container img {
  height: 100%;
  width: 100%;
  margin-left: -10%;
  object-fit: cover;
  opacity: 0.9;
  z-index: 0;
  mask: radial-gradient(
    ellipse closest-side at center,
    #ffffff,
    transparent 100%
  );
}

.card-container {
  display: grid;
  gap: 2rem;
  grid-template-rows: repeat(auto-fit, minmax(150px, 4fr));
  justify-items: center;
  width: 100%;
}

.partner-card {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 1rem;
  border-bottom: 8px solid #f30b92;
  height: fit-content !important;
  width: 100%;
  width: 170px;
  width: 100%;
  padding: 10px;
  block-size: auto;
  overflow: hidden;
}

@media (max-width: 600px) {
  .card-container {
    display: block;
  }

  .partner-card p {
    font-size: 16px !important;
    text-align: center !important;
  }
}

.button {
  border: none;
  cursor: pointer;
  background-color: #fff;
}

.card-texts-container {
  height: 75px;
  text-align: center !important;
}

.card-img-container img {
  height: 70px;
}

.card-icon-container img {
  height: 55px;
  display: grid;
  margin-top: -55px;
  place-content: center;
}

.card-icon-container-up img {
  height: 55px;
  display: grid;
  place-content: center;
  transform: rotate(180deg);
}

@media (max-width: 767px) {
  .partner-height {
    height: auto;
    width: 100vw;
  }

  .cards-container {
    padding-inline: 10px;
  }
}

.s-features-height {
  height: 100vh;
  width: 100%;
  position: relative;
}

.s-feature-container {
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  overflow: hidden;
}

.s-feature-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.8;
  z-index: 0;
  mask: radial-gradient(ellipse closest-side at center, #fff, transparent 200%);
}

.card-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  justify-items: center;
}

.feature-card {
  background-color: #fff;
  border-radius: 10px;
  border-bottom: 8px solid #89c599;
  height: fit-content !important;
  height: 150px;
  padding: 10px;
  width: 100%;
  overflow: hidden;
}

.card-text-container {
  height: 105px;
  font-size: 13px;
}

.card-img-container img {
  height: 55px;
}

@media (max-width: 767px) {
  .s-features-height {
    height: auto;
    min-height: 100vh;
  }

  .cards-container {
    padding-inline: 10px;
  }

  .feature-card {
    margin: 12px 0px;
  }
}

.legacy-height {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.legacy-container {
  height: 100%;
  width: 100%;
  top: 0;
  position: absolute;
  overflow: hidden;
}

.legacy-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.8;
  z-index: 0;
  mask: radial-gradient(ellipse closest-side at center, #fff, transparent 200%);
}

.legacyTitle {
  color: #fff;
  display: block !important;
  flex-direction: column;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 100%;
  margin-top: -2.5rem;
}

.card-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  justify-items: center;
}

.legacy-card {
  background-color: #fff;
  border-radius: 10px;
  align-self: flex-end;
  border-bottom: 8px solid #89c599;
  height: fit-content !important;
  padding: 10px;
  width: 100%;
  margin-top: 1rem;
  block-size: auto;
  overflow: hidden;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .card-container {
    display: block;
  }

  .legacy-card {
    margin: 12px;
  }

  .legacy-card p {
    font-size: 16px !important;
    text-align: center !important;
  }
}

.button {
  border: none;
  cursor: pointer;
  background-color: #fff;
}

.card-text-green-container {
  height: 55px;
}

.card-img-green-container img {
  height: 70px;
}

.card-icon-green-container img {
  height: 45px;
  display: grid;
  margin-top: -25px;
  place-content: center;
}

.card-icon-green-container-down img {
  height: 55px;
  display: grid;
  place-content: center;
  transform: rotate(180deg);
}

@media (max-width: 767px) {
  .legacy-height {
    height: auto;
    min-height: 100vh;
  }

  .cards-container {
    padding-inline: 10px;
  }
}

.about-height {
  min-height: 100vh;
}

select:invalid {
  color: gray;
}

option {
  color: #6c757d;
}

.s-about {
  height: 100%;
  object-fit: cover;
  opacity: 1;
  z-index: 0;
  mask: radial-gradient(ellipse closest-side at center, transparent 180%);
}

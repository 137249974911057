.btn {
  color: white;
  border-radius: 10px;
  padding-inline: 1.6rem;
  padding-block: 10px;
}

.btn-2 {
  color: white;
  border-radius: 5px;
  padding-inline: 1.6rem;
  padding-block: 5px;
  border: 0;
  margin: 2rem;
}

.color-1 {
  background-color: #9c1adf;
}

.color-2 {
  background-color: #9c1adf;
  border: 1px solid white;
}

.color-3 {
  background-color: #0d2a48;
}

.btn img {
  width: 1.5rem;
  margin-inline: 5px;
}
